import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

/**
 * Simple service providing the current route as observable
 */
@Injectable({
  providedIn: 'root'
})
export class CurrentRouteService {

  public currentRoute$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    private router: Router,
  ) {

    // monitor the router
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute$.next(event.urlAfterRedirects);
      }
    });
  }
}